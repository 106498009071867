import React from "react";

const CreationDocumentary = () => {
  return (
    <>
      <h1>Documentary</h1>
    </>
  );
};

export default CreationDocumentary;
